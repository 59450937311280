/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote {
  &:before, &:after {
    content: '';
    content: none; } }

q {
  &:before, &:after {
    content: '';
    content: none; } }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*========================= CUSTOM SCSS ======================*/

$color__main: #0a253b;
$color__grey: #cbded8;
$color__midgrey: #cbded8;
$color__dark: #434343;
$color__icons: #098081;

$xlarge: 1280px;
$large: 1200px;
$med: 992px;
$small: 767px;
$xsmall: 600px;
$tiny: 400px;

/*========================= CUSTOM SCSS ======================*/

body {
  font-family: "baskerville-urw", serif!important;
  line-height: 1.2!important;
}

.main__head {
  font-size: 72px;
  @media (max-width: $large) {
    font-size: 64px;
  }

  @media (max-width: $small) {
    font-size: 55px;
  }
  @media (max-width: $xsmall) {
    font-size: 40px;
  }
}

h2 {
  font-size: 40px;
  @media (max-width: $xsmall) {
    font-size: 32px;
  }
}

h3 {
  font-size: 31px;

  @media (max-width: $large) {
    font-size: 28px;
  }

  @media (max-width: $tiny) {
    font-size: 24px;
  }
}

h4 {
  font-family: 'sofia-pro', Arial, Helvetica, sans-serif;
  font-weight: bold!important;
  font-size: 20px;
}

p {
  font-size: 24px;
  padding-bottom: 20px;
  @media (max-width: $large) {
    font-size: 22px;
  }
  @media (max-width: $tiny) {
    font-size: 18px;
  }
}

img {
  display: block;
  width: 100%;
}

header {
  background-color: #fff;

  ul {
    float: right;
    li {
      display: inline;
      color: $color__main;
    }
  }
}

.img-push {
  padding-bottom: 30px;
}

.mob-image-push {
  @media (max-width: $small) {
    padding-top: 30px;
  }
}

.section-push {
  padding-bottom: 120px;
}

.clearfix {
  clear: both;
}

/*========================= CUSTOM SCSS ======================*/

header {
  background-color: white;
  padding: 30px 90px;
  @media (max-width: $xlarge) {
    padding: 30px 20px;
  }
  @media (max-width: $large) {
    padding: 15px 20px;
  }
  nav {
    .logo {
      width: 140px;
      @media (max-width: $large) {
        width: 100px;
      }
    }

    ul {
      margin-top: 50px;
      @media (max-width: $large) {
        margin-top: 35px;
      }
      @media (max-width: $med) {
        display: none;
      }
      li {
        padding-right: 35px;
        font-size: 18px;
        font-family: 'sofia-pro', Arial, Helvetica, sans-serif;
        @media (max-width: $large) {
          font-size: 16px;
          padding-right: 18px;
        }

        a {
          color: $color__main;

          &:hover {
            text-decoration: none;
          }
        }

        &:last-of-type {
          padding: 0;
        }
      }
    }
  }
}

#hero {
  height: 800px;
  background-image: url('../img/jacks-hero.jpg');
  background-repeat: no-repeat;
  position: relative;
  background-position: center center;
  @media (max-width: $large) {
    height: 620px;
  }

  @media (max-width: $tiny) {
    height: 400px;
  }
  figure {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    @media (max-width: $large) {
      width: 350px;
    }
    @media (max-width: $tiny) {
      width: 220px;
    }
  }
}

.hero__copy {
  background-color: $color__main;
  color: white;
  padding: 60px 0 50px;
  text-align: center;

  @media (max-width: $xsmall) {
    padding: 40px 10px;
  }

  h2 {
    margin-bottom: 30px;
  }

  .fa.fa-chevron-down {
    font-size: 30px;
    margin-top: 20px;
    color: white;
  }

  p {
    max-width: 600px;
    margin: 0 auto;
  }
}

/*========================= CUSTOM SCSS ======================*/

#intro {
  position: realtive;
  .watermark-overlay {
    position: relative;
    z-index: 100;
    top: 440px;
    img {
      position: absolute;
      right: 0;
      width: 500px;
    }
  }
}

#intro {
  background-color: $color__grey;
  padding: 150px 0;
  color: $color__main;
  // background-image: url('../img/JM_Watermark_White.png');
  // background-repeat: no-repeat;
  // background-position: center right;
  @media (max-width: $med) {
    padding: 50px 0;
  }
  @media (max-width: $small) {
    padding: 50px 15px;
  }

  h3 {
    margin-bottom: 30px;
  }

  h4 {
    color: $color__dark;
    font-size: 20px;
    padding-bottom: 30px;
    @media (max-width: $med) {
      padding-bottom: 50px;
    }
    @media (max-width: $tiny) {
      font-size: 18px;
    }
  }

  .divider {
    border-color: $color__main;
    background-color: $color__main;
    width: 110px;
    margin: 60px 0 30px;
  }

  ul {
    li {
      font-size: 24px;
      display: inline-block;
      padding-left: 20px;
      padding-bottom: 10px;
      @media (max-width: $large) {
        font-size: 22px;
      }
      @media (max-width: $tiny) {
        font-size: 18px;
      }

      &::before {
        height: 8px;
        width: 8px;
        background-color: #0a253b;
        border-radius: 100%;
        content: "";
        display: inline-block;
        margin-left: -20px;
        margin-right: 14px;
        margin-top: 0px;
      }
    }
  }
}

/*========================= CUSTOM SCSS ======================*/

#history {
  padding: 150px 0;
  text-align: center;
  background: white url('../img/history_bg.jpg');
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: top right;

  @media (max-width: $large) {
    padding: 100px 0;
  }

  h1 {
    color: $color__main;
  }

  .history__copy {
    padding: 0 50px 70px;

    @media (max-width: $small) {
      padding: 0 10px 70px;
    }

    h3 {
      margin-bottom: 40px;
    }
  }

  .history__gallery {
    padding-bottom: 15px;
  }
}

/*========================= CUSTOM SCSS ======================*/

#place {
  background-color: $color__main;
  padding: 150px 0;
  color: white;
  @media (max-width: $large) {
    padding: 100px 0;
  }

  @media (max-width: $small) {
    padding: 50px 15px;
  }
  h4 {
    margin: 5px 0 10px;
    @media (max-width: $small) {
      margin-top: 20px;
    }
  }

  .col-sm-4 {
    &:last-of-type {
      p {
        margin-bottom: 20px;
      }
    }
  }
}

/*========================= CUSTOM SCSS ======================*/

#building {
  background-color: $color__main;
  color: white;
  padding-top: 100px;
  height: 600px;

  @media (max-width: $large) {
    height: 500px;
  }

  @media (max-width: $med) {
    height: auto;
    padding: 50px 0 75px;
  }

  @media (max-width: $small) {
    padding: 10px 15px 50px;

    img {
      max-width: 400px;
      margin: 30px auto 0;
    }
  }
}

/*========================= CUSTOM SCSS ======================*/

#uses {
  padding: 250px 0 0px;
  background-color: $color__grey;
  padding-bottom: 250px;

  @media (max-width: $med) {
    padding: 100px 0;
  }

  @media (max-width: $small) {
    padding: 50px 15px 30px;
  }
  .uses__gallery {
    margin-bottom: 150px;

    @media (max-width: $small) {
      margin-bottom: 50px;
    }
  }
}

/*========================= CUSTOM SCSS ======================*/

#potential {
  margin-top: -250px;
  padding-bottom: 150px;

  @media (max-width: $med) {
    margin-top: 0;
    padding: 100px 0;
  }
  @media (max-width: $small) {
    padding: 50px 15px;
  }
  h1 {
    margin-top: 150px;

    @media (max-width: $med) {
      margin-top: 50px;
    }
  }

  p {
    @media (max-width: $med) {
      margin-bottom: 50px;
    }
  }
}

/*========================= CUSTOM SCSS ======================*/

#icons {
  background-color: $color__midgrey;
  padding: 150px 0 0;
  @media (max-width: $med) {
    padding-bottom: 60px;
  }
  @media (max-width: $small) {
    padding: 50px 15px;
  }

  .icon__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1170px;
    margin: 0 auto 50px;
    padding: 0 20px;
    @media (max-width: $tiny) {
      padding: 0;
    }

    .icon__wrapper {
      text-align: center;
      color: $color__icons;
      flex: 1 1 auto;
      margin-top: 25px;

      img {
        max-width: 132px;
        margin: 0 auto;

        @media (max-width: $tiny) {
          max-width: 115px;
          padding: 0 5px;
        }
      }

      &:nth-child(5) {
        margin-right: 0;
      }

      h4 {
        margin-top: 10px;
        @media (max-width: $tiny) {
          font-size: 14px;
        }
      }
    }
  }

  .row-push {
    @media (max-width: $large) {
      padding-top: 30px;
    }
    @media (max-width: $small) {
      padding-top: 0px;
    }
  }

  .mob-push {
    @media (max-width: $small) {
      padding-top: 20px;
    }
  }
}

/*========================= CUSTOM SCSS ======================*/

#contact {
  position: realtive;
  .watermark-overlay {
    position: relative;
    z-index: 100;
    bottom: 435px;
    img {
      position: absolute;
      left: 0;
      width: 500px;
    }
  }
}

@media (max-width: $small) {
    .watermark-overlay {
      display: none;
    }
}

@media (max-width: $med) {
.watermark-overlay img {
      opacity: 0.4;
    }
}

@media (max-width: 1200px) {
 .watermark-overlay img {
      opacity: 0.5;
  } 
}

#contact {
  padding: 250px 0;
  margin-top: -150px;
  background-color: white;
  color: $color__dark;

  @media (max-width: $med) {
    margin-top: 0;
    padding: 100px 0;
  }

  @media (max-width: $small) {
    padding: 50px 15px;
  }

  h1 {
    text-align: center;
  }

  h4 {
    padding-bottom: 20px;
  }

  form {
    margin-top: 30px;

    input {
      width: 100%;
      padding: 20px 15px;
      color: $color__dark;
      margin-bottom: 25px;
      box-shadow: none;
      outline: none;
      border: 1px solid #96989b;
      font-size: 16px;
      font-family: 'sofia-pro', Arial, Helvetica, sans-serif;
      border-radius: 0;
      -webkit-appearance: none;

      &::placeholder {
        font-family: 'sofia-pro', Arial, Helvetica, sans-serif;
      }
    }

    button {
      background-color: $color__main;
      padding: 20px 30px;
      border: 0;
      color: white;
      font-size: 20px;
    }
  }

  .contact__cta {
    margin-top: 5px;

    @media (max-width: $med) {
      margin-bottom: 50px;
    }

    .fitzroy {
      margin-top: 15px;
      width: 64px;
    }
  }
  a.clear-appearance {
    color: #434343;
    &:hover {
      text-decoration: none;
      color: #0a253b;
    }
  }
}

/*========================= CUSTOM SCSS ======================*/

footer {
  background-color: $color__main;
  padding: 30px 0;

  a {
    color: white;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }

  p {
    display: inline-block;
    font-size: 15px;
    text-align: right;
    padding-right: 15px;
    font-weight: 300;
    color: white;
    font-family: 'sofia-pro', Arial, Helvetica, sans-serif;
    margin-top: 55px;
    @media (max-width: $large) {
      margin-top: 35px;
    }
    @media (max-width: $med) {
      display: block;
      text-align: center;
    }

    &:last-of-type {
      padding-right: 0;

      @media (max-width: $med) {
        margin-top: 0;
      }
    }
  }

  .logo-push {
    @media (max-width: $med) {
      text-align: right;
    }
  }
  #Layer_3 {
    width: 150px;

    @media (max-width: $large) {
      width: 120px;
    }
  }

  #Layer_4 {
    width: 153px;
    @media (max-width: $large) {
      width: 120px;
      margin-top: 5px;
    }
  }
}

/*========================= HAMBURGER MENU ======================*/

.pull-right {
  @media (max-width: $med) {
    text-align: right;
    z-index: 666;
  }
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  margin: 1em 1em 0 0;
  text-align: right;
  z-index: 666;

  @media (min-width: $med) {
    display: none;
  }

  .bar1,.bar2,.bar3 {
    width: 30px;
    height: 2px;
    background-color: $color__dark;
    margin: 6px 0;
    transition: 0.4s;

    &.change {
      background-color: $color__midgrey;
    }
  }

    .change.bar1 {
      transform: rotate(-45deg) translate(-5px, 5px);
    }

    .change.bar2 {
      opacity: 0;
    }

    .change.bar3 {
      transform: rotate(45deg) translate(-5px, -7px);
    }
}

.mobile-menu {
  @media (min-width: $med) {
    display: none;
  }

  #mob-nav {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    li {
      padding-bottom: 0.7em;
      a {
        color: #eee;
        font-size: 2.5em;

        &:hover {
          border-bottom: 2px solid #eee;
        }
      }
    }
  }

  .mob-overlay {
    display: none;

    .mob-list {
      display: block;
      margin: 0 auto;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      li {
        display: block;
        font-size: 35px;
        padding-right: 0;
        padding-bottom: 40px;

        @media (max-width: $tiny) {
          font-size: 24px;
          padding-bottom: 24px;
        }
        a {
          color: white;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &.open {
      display: block;
      position: fixed;
      width: 100%;
      height: 100%;
      margin: 0;
      left: 0;
      top: 0;
      z-index: 99;
      background-color: rgb(0,0,0);
      background-color: rgba(0,0,0, 0.88);
      overflow-x: hidden;
      text-align: center;
    }
  }
}
